:root {
  --aaa-primary: ##33496b;
  --aaa-primary-dk: #7e8299;
  --aaa-black: #004365;
  --aaa-gray-lt: #bbbdbe;
  --aaa-main-background: #ffffff;
  --aaa-buton-clr: #fff;
  --aaa-button-highligt: #306986;
  --aaa-button-inverted-light: #fff;
  --aaa-button-inverted-dark: #F5F8FA;
  --aaa-button-grey: #F5F8FA;
  --aaa-button-light-grey: #eff2f5;
  --aaa-accent: #fff;
  --aaa-warning-clr: #FFFFCC;
  --base-font: "QuadraatSansRegular";
  --base-fs: clamp(12px, 100%, 16px);
  --base-text-clr: #33496b;
  --inverted-text-clr: #004365;
  --font-bold: 500;
}

/* elements */
html {
  scroll-behavior: smooth;
}

p {
  font-weight: 300;
}

body {
  background-color: var(--aaa-main-background);
  background-image: none;
}

/* fonts */
html,
body {
  font-size: var(--base-fs) !important;
  font-family: 'Metromedium2Com', Arial, sans-serif;
}

.header-font {
  font-family: 'Metromedium2Com', Arial, sans-serif;
}

/* other */
.align-center {
  margin: auto;
}

.bg-custom-button {
  background-color: var(--aaa-warning-clr);
  border-color: var(--aaa-warning-clr);
}

.bg-custom-button .btn-close:focus {
  box-shadow: 0 0 0 0.25rem var(--aaa-warning-clr);
}

#paging_holder .btn:not(:last-child), #paging_holder .btn:not(:first-child) {
  margin-inline: 0.5rem;
}

.card.custom-opacity {
  background-color: rgba(255, 255, 255, 0);
}

.card__hero h1 {
  font-size: calc(5rem + 0.1vw);
}

.cursor-pointer {
  cursor: pointer;
}

.footer-link {
  font-size: 1.2em;
  color: var(--inverted-text-clr);
}

.footer-link:hover {
  font-size: 1.2em;
  color: var(--inverted-text-clr);
  text-decoration: underline !important;
}

.highlight {
  background-color: var(--aaa-accent);
  color: var(--aaa-black) !important;
  border-radius: 0;
}

.highlight2 {
  background-color: #FAF0EB;
  color: #ff5000 !important;
  font-size: 1.35rem !important;
  border-radius: 0;
  width: 97.3vw;
  position: relative;
  left: calc(-50vw + 50%);
  height: 300px;
  padding-left: 30%;
  padding-right: 30%;
  padding-top: 6.6%;
  border-radius: 8px;
  margin-top: 0rem;
  margin-left: 2rem;
  margin-right: 1.5rem;
  margin-bottom: 0rem;
}

.highlight3 {
  background-color: var(--aaa-accent);
  color: var(--aaa-black) !important;
  font-size: 1.35rem !important;
  border-radius: 0;
  width: 97.5vw;
  position: relative;
  left: calc(-50vw + 50%);
  height: 300px;
  padding-left: 30%;
  padding-right: 30%;
  padding-top: 7%;
  border-radius: 8px;
  margin-top: 0rem !important;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
}

#home .landing-bg {
  background-color: #fff;
}

#home .landing-header {
  border-bottom: 2px solid var(--aaa-primary);
}

.home-info {
  border-radius: 8px;
  background-color: #EDF6FA;
  -webkit-margin-after: 0;
  margin-block-end: 0;
  margin-top: 1.5rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 2.8rem !important;
}

.home-info .home-info__left {
  color: var(--inverted-text-clr);
  margin-top: 1rem;
}

.home-info .home-info__right {
  color: var(--inverted-text-clr);
  opacity: 0.8;
  margin-top: 1rem;
}

.invert-logo {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.landing-dark-bg {
  background-color:#E9F4F5;
}

.landing-border {
  border: 1px dashed var(--aaa-primary);
}

.landing-border .separator {
  --sep-style: solid;
  --sep-clr: var(--aaa-primary);
}

.landing-separator {
  border: 1px dashed var(--aaa-gray-lt);
}

.landing-dark-bg .landing-separator {
  border: 1px dashed var(--aaa-primary);
}

.light-branded-bg {
  background-color: var(--aaa-accent);
}

.modal {
  --border-size: 1px;
  --border-style: solid;
  --border-clr: $aaa-primary;
  --border: var(--border-size) var(--border-style) var(--border-clr);
}

.modal-header {
  border-bottom: var(--border);
}

.modal-footer {
  border-top: var(--border);
}

.no-access {
  background-color: var(--aaa-main-background);
  border-radius: 0;
}

#paging_holder .btn:not(:last-child), #paging_holder .btn:not(:first-child) {
  margin-inline: 0.5rem;
}

#paging_holder .btn:last-child {
  margin-inline: 0.5rem 0;
}

#paging_holder .btn:first-child {
  margin-inline: 0 0.5rem;
}

.separator {
  display: block;
  height: 0;
  border-bottom: var(--sep-w, 1px) var(--sep-style, dashed) var(--sep-clr, #eff2f5);
}

.stories {
  background-color: #fff;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

.stories [data-grid="true"] {
  --gap: 3rem;
}

.stories.custom-card img {
  width: 40%;
  display: block;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
}

.stories .custom-card__heading {
  font-size: 1.3rem;
  color: var(--inverted-text-clr);
  -webkit-margin-after: 1rem;
          margin-block-end: 1rem;
}

table {
  --tbl-valign: middle;
}

table.table {
  color: var(--base-text-clr);
}

table tr.t-primary {
  color: var(--aaa-primary);
}

table tr td, table tbody tr td span {
  vertical-align: var(--tbl-valign);
}

table tbody tr td, table tbody tr td span {
  font-size: clamp(12px, 85%, 1.3rem);
}

table .actions_column {
  width: clamp(150px, 100%, 300px);
  -webkit-margin-start: auto;
          margin-inline-start: auto;
}

table .actions_column span,
table .actions_column a {
  margin-inline: 0.5rem;
}

.table-text-visible {
  color: var(--base-text-clr);
}

.table-text-highlight {
  color: var(--base-text-clr);
}

.t-primary {
  color: #ff5000;
}

.text_visible {
  color: var(--aaa-primary);
}

.warning-banner {
  background-color: var(--aaa-warning-clr);
  padding: 0.5%;
  margin-top: 5px;
}

.warning-triangle {
  color: var(--aaa-primary) !important;
}

/* grid */
[data-grid="true"] {
  --display: grid;
  --gap: 1rem;
  --fraction: 1fr;
  --min-w: 250px;
  --ideal-w: 75%;
  --max-w: 600px;
  display: var(--display);
  gap: var(--gap);
  -ms-grid-columns: (minmax(var(--item-w), var(--fraction)))[var];
      grid-template-columns: repeat(var(--fill, auto-fit), minmax(var(--item-w), var(--fraction)));
}

[data-grid="true"][data-grid-item-size="sm"] {
  --item-w: 160px;
}

[data-grid="true"][data-grid-item-size="md"] {
  --item-w: 250px;
}

[data-grid="true"][data-grid-item-size="md 4"] {
  --item-w: 850px;
}

[data-grid="true"][data-grid-item-size="lg"] {
  --item-w: 300px;
}

[data-grid="true"][data-grid-fill="auto-fill"] {
  --fill: auto-fill;
}

/* button */
.btn-standard {
  --btn-bg-clr: var(--aaa-primary);
  --btn-border-clr: var(--aaa-primary);
  --btn-clr: #fff;
  background-color: #004365;
  border-color: var(--btn-border-clr);
  color: var(--btn-clr);
}

.btn-standard i {
  color: var(--btn-clr); 
}

.btn-standard:hover {
  background-color: var(--aaa-button-highligt);
  border-color: var(--aaa-button-highligt);
  color: var(--aaa-buton-clr);
}

.btn-inverted-light {
  background-color: var(--aaa-button-inverted-light);
  border-color: var(--aaa-button-inverted-light);
  color: var(--aaa-primary); 
}

.btn-inverted-light i {
  color: var(--aaa-primary); 
}

.btn-inverted-light:hover {
  background-color: var(--aaa-button-grey);
  border-color: var(--aaa-button-grey);
}

.btn-inverted-light:hover i {
  color: var(--aaa-primary-dk); 
}

.btn-inverted-dark {
  background-color: var(--aaa-button-grey);
  border-color: var(--aaa-button-grey);
}

.btn-inverted-dark i {
  color: var(--aaa-primary); 
}

.btn-inverted-dark:hover {
  background-color: var(--aaa-button-light-grey);
  border-color: var(--aaa-button-light-grey);
}

.btn-inverted-dark:hover i {
  color: var(--aaa-primary-dk); 
}

.btn-hover-inverted {
  background-color: var(--aaa-button-grey);
  border-color: var(--aaa-button-grey);
}

.btn-hover-inverted i {
  color: var(--aaa-primary-dk); 
}

.btn-hover-inverted:hover {
  background-color: var(--aaa-button-light-grey);
  border-color: var(--aaa-button-light-grey);
}

.btn-hover-inverted:hover i {
  color: var(--aaa-primary); 
}

.btn-modal-icon {
  background-color: var(--aaa-accent);
  border: 1px solid #fff !important;
}

.btn-modal-icon:hover {
  background-color: #fff;
}

.btn.btn-light.btn-icon:hover {
  --btn-clr: var(--aaa-primary);
}

.btn.btn-light.btn-icon:hover span,
.btn.btn-light.btn-icon:hover i {
  color: var(--btn-clr);
}

.btn.btn-light.btn-icon.btn-active-light-danger:hover {
  --btn-clr: var(--bs-danger);
}

.btn.btn-light.btn-icon.btn-active-light-danger:hover span,
.btn.btn-light.btn-icon.btn-active-light-danger:hover i {
  color: var(--btn-clr);
}

.btn.btn-white.t-primary {
  color: var(--aaa-primary);
}

.btn.btn-white.t-primary i {
  color: var(--aaa-primary);
}

.btn.btn-white.t-primary:hover {
  color: var(--aaa-primary-dk);
}

.btn.btn-white.t-primary:hover i {
  color: var(--aaa-primary-dk);
}

.center-button {
  padding-right: 0 !important;
}

.custom-card img {
  width: 40%;
  display: block;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
}